/* Global CSS can be placed here */

.App-header {
  position: fixed;
  top: 0;
  z-index: 11;
  width: 100%;
}

.App-body {
  padding: 0px 15px 30px 15px;
}

.bp4-dark.App {
  background-color: #30404d;
}

.App-footer .timezone-preferences .bp4-radio {
  margin-bottom: 0;
}

.remove-opportunity {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.new-pair-form .bp3-button-text {
  width: 150px;
}

.new-pair-form .bp3-html-select {
  width: 193px;
}

.new-pair-form-container {
  width: 600px;
}

.button-spinner {
  display: inline-flex;
  margin-left: 5px;
}

.button-spinner .bp4-spinner-head {
  stroke: #fff;
}

.trade-feed-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.trade-feed-container table {
  display: block;
}

.trade-feed-container table tbody {
  text-align: center;
}

.trade-feed-container table thead {
  position: sticky;
  top: 1px;
  background: #f7fafc;
  z-index: 1;
}

.trade-feed-container table th {
  border: 1px solid rgba(229, 231, 235, 1);
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 rgba(229, 231, 235, 1), inset 0 -1px 0 rgba(229, 231, 235, 1);
}

.trade-feed-container table tr.gray {
  background: #e5e7eb;
}

.trade-feed-container table tr.orange {
  background: #fed7aa;
}

.trade-feed-container table tr.blue {
  background: #bfdbfe;
}

.trade-feed-container table tr.green {
  background: #bbf7d0;
}

.trade-feed-container table tr.danger {
  background: #fecaca;
}

.trade-feed-container table .pair-name {
  width: 200px;
  text-align: left;
  font-weight: 600;
}

.page-input .bp4-input-group .bp4-input {
  width: 40px;
  height: 25px;
  border-radius: 4px;
}

.badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  background: #db3737;
  font-weight: bold;
  color: #fff;
}

.new-pair-form .bp4-button-text {
  width: 150px;
}

.new-pair-form .bp4-html-select {
  width: 193px;
}

.long-order {
  color: #69e189;
  font-weight: 600;
}

.short-order {
  color: #db3737;
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.bp4-toast-container.app-toaster {
  position: fixed;
}

.active-summary {
  margin: 10px 20px;
}

.active-summary-collapsed {
  position: fixed;
  top: 120px;
  right: 0;
  z-index: 99;
  background-color: #000000;
}

.active-summary .list {
  height: calc(100vh - 125px);
  overflow: auto;
}

.active-pair-sort-by select {
  font-size: 0.75rem;
}

.active-pair-box {
  padding: 5px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.long-and-short {
  position: relative;
  border-right: 3px solid #69e189;
  border-left: 3px solid #db3737;
}

.long-and-short::before,
.long-and-short::after {
  background: linear-gradient(to right, #db3737 25%, #db3737 25%, #db3737 50%, #69e189 50%, #69e189 75%, #69e189 75%);
  position: absolute;
  content: '';
  height: 3px;
  right: 0;
  left: 0;
}

.long-and-short::before {
  top: 0;
}

.long-and-short::after {
  bottom: 0;
}

.only-long {
  border: 3px solid #69e189;
}

.only-short {
  border: 3px solid #db3737;
}

.close-active-pairs {
  margin-right: -5px;
}

.custom-date-picker {
  font-size: 13px;
}

.custom-date-picker .bp4-menu-item {
  font-weight: 600;
}

.editor-custom-table {
  overflow: auto;
  max-height: calc(100vh - 270px);
}

.handsontable .htDimmed {
  background-color: rgba(31, 30, 36, 0.08);
}

.bp4-popover2.bp4-popover2-content-sizing.active-order-details .bp4-popover2-content {
  max-width: 550px;
  padding: 10px;
}

.bp4-tab.pair-tab {
  display: block !important;
  padding: 0 5px 0 5px !important;
  margin-right: 0px;
}

.bp4-tab-panel.pair-tab {
  padding-left: 0;
  margin-left: 0;
}

.pairs-select .pair > div {
  overflow: visible;
}

.pairs-select .pair .content {
  min-height: 60px;
}

.app-name {
  color: #394B59;
}

.app-name:hover {
  color: #10161A;
}

:global(.bp4-dark) .app-name  {
  color: #E1E8ED;
}

:global(.bp4-dark) .app-name:hover  {
  color: #F5F8FA;
}
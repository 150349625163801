/* CSS Module rules which can be shared between components can be placed here */

:global(.bp4-dark) .item:hover  {
  background-color: #202B33;
}

:global(.bp4-dark) .highlighted  {
  background-color: #202B33;
}

.item:hover  {
  background-color: #E1E8ED;
}

.highlighted  {
  background-color: #E1E8ED;
}
.dialog {
    width: 100%;
    height: 100vh;
    overflow: auto;
    margin: 0;
}

.smallDialog {
    width: 97%;
    height: 95vh;
    /* overflow: auto; */
    margin: 0;
}